<template>
  <div class="players view">
    <app-header :title="__('playerRegistrationLogs')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{ __('common.total') }}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div v-if="hasOneRoles([])" class="control-bar__button" :title="__('common.add')"
             @click="addPlayerRegistrations()" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="playerRegistrationLogs"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <run-player-registratons ref="runPlayerRegistrations"/>
  </div>
</template>

<script>
import apiSecured from '../../api/secured';
import DataTable from '../../components/DataTable';
import AppHeader from '../../components/AppHeader';
import { notificationsMixin } from '../../mixins/notifications';
import { localizationMixin } from '../../mixins/localization';
import { paginationMixin } from "../../mixins/pagination";
import { pageTitleMixin } from "../../mixins/pageTitle";
import { userMixin } from "../../mixins/user";
import RunPlayerRegistratons from "../../components/Ingame/RunPlayerRegistratons";

export default {
  mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin, userMixin],
  components: {
    RunPlayerRegistratons,
    AppHeader,
    DataTable,
  },
  data: () => ({
    loading: false,
    playerRegistrationLogs: [],
    dataTableFields: [],
    recordActions: [],
  }),
  methods: {
    refreshDelayed(delay) {
      setTimeout(this.refresh, delay);
    },
    refresh() {
      this.loading = true;
      let params = this.getPaginationParams();
      this.$router.push({ name: this.$route.name, query: params }).catch(err => {
      });
      apiSecured.get('/ingame/civilization/playerregistrationlog', { params }).then(async (res) => {
        this.playerRegistrationLogs = res.data;
        await this.enrichRecords();
        this.updatePagination(res);
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    },
    async enrichRecords() {
      const playerIds = [];
      const userIds = [];
      this.playerRegistrationLogs.forEach(log => {
        if (log.playerId && !playerIds.includes(log.playerId)) {
          playerIds.push(log.playerId);
        }
        if (log.userId && !userIds.includes(log.userId)) {
          userIds.push(log.userId);
        }
      });
      if (playerIds.length > 0) {
        apiSecured.get(`/ingame/civilization/player/(${playerIds.join(',')})`).then(async (res) => {
          this.playerRegistrationLogs = this.playerRegistrationLogs.map(log => {
            log.playerAvatar = 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
            const player = res.data.find(player => player.id === log.playerId);
            if (player) {
              log.playerName = player.name;
              log.playerAvatar = player.avatar;
            }
            return log;
          })
          this.showInfoNotification('Players loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
      if (userIds.length > 0) {
        apiSecured.get(`/community/user/(${userIds.join(',')})`).then(async (res) => {
          this.playerRegistrationLogs = this.playerRegistrationLogs.map(log => {
            log.userAvatar = 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png';
            const user = res.data.find(user => user.id === log.userId);
            if (user) {
              log.userName = user.userName;
              log.userAvatar = user.avatar;
            }
            return log;
          })
          this.showInfoNotification('Users loaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    },
    addPlayerRegistrations() {
      this.$refs.runPlayerRegistrations.showDialog();
    },
  },
  created() {
    this.setPageTitle(this.__('playerRegistrationLogs'));

    this.dataTableFields = [
      { name: "id", title: this.__('entity.common.id'), type: "default" },
      { name: "userAvatar", title: 'Avatar', type: "image" },
      { name: "userName", title: this.__('entity.auth.user.username'), type: "default" },
      { name: "playerAvatar", title: 'Avatar', type: "srcOrSvg" },
      { name: "playerName", title: this.__('entity.common.player'), type: "default" },
      { name: "successful", title: this.__('successful'), type: "boolean" },
      { name: "message", title: this.__('successful'), type: "default" },
      { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
    ];
    this.refresh();
  }
}
</script>
