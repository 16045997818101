<template>
  <div class="player-registration">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('playerRegistration')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <text-area
                  v-model="codes"
                  :label="'Registration coded'"
              />
            </div>
            <div class="flex xs12">
              <div class="tile">{{cleaned}}</div>
            </div>
            <div class="flex xs12 mt">
              <div class="btn btn--success" :loading="loading" :disabled="codes.length <= 0"
                   @click="save" v-slashes>{{ __('common.save_changes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import apiSecured from "../../api/secured";
  import { notificationsMixin } from "../../mixins/notifications";

  export default {
    mixins: [localizationMixin, notificationsMixin],
    data: () => ({
      codes: '',
      show: false,
      loading: false
    }),
    computed: {
      cleaned() {
        return this.codes.replace(/&quot;/g,'"');
      }
    },
    methods: {
      showDialog() {
        this.show = true;
        this.$emit('shown');
      },
      close() {
        this.codes = '';
        this.show = false;
        this.$emit('closed');
      },
      save() {
        if(this.loading) {
          return;
        }
        const payload = JSON.parse(this.cleaned);
        this.loading = true;
        apiSecured.patch('/ingame/civilization/player/register-multiple', payload).then((res) => {
          this.token = res.data.token;
          this.loading = false;
          this.showSuccessNotification(this.__('common.saved'));
          this.loading = false;
        }).catch((error) => {
          this.token = '';
          this.loading = false;
          this.showErrorNotifications(error);
          this.loading = false;
        });
      },
    }
  }
</script>